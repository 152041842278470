import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';

import { PAGES, BASE_URL } from 'config';
import { mapStateToProps, FORMAT_GRAPH_DATA } from 'utilities';
import { Layout } from 'containers';
import { ActivityCard } from 'components';

class UserFavouritesPage extends PureComponent {
  render() {
    const { data, user } = this.props;

    const ACTS = FORMAT_GRAPH_DATA(data.allActivityPages);
    const FAVED_ACTS = ACTS.filter(act => user.favorites.activities.includes(act.api_id));

    return (
      <Layout page="user/favourites">

        <Helmet
          title="Select Your Favourite Activities for Your New Zealand Vacation | Haka Tours, NZ"
          meta={[
            {
              name: 'description', content: 'This is where the activities that you have selected as your favourites for your Haka Tours adventure live. Select your absolute favourites and add them on to your New Zealand tour.',
            },
            { name: 'og:title', content: 'Select Your Favourite Activities for Your New Zealand Vacation | Haka Tours, NZ' },
            {
              name: 'og:description', content: 'This is where the activities that you have selected as your favourites for your Haka Tours adventure live. Select your absolute favourites and add them on to your New Zealand tour.',
            },
          ]}
          link={[{ rel: 'canonical', href: `${BASE_URL}/user/favourites/` }]}
        />

        <section className="l-section l-user-favourites">
          <div className="l-container">
            <h1 className="c-heading c-heading--h1">Favourite Activities</h1>
            {
              FAVED_ACTS.length
                ? (
                  <>
                    <div className="l-grid l-grid--4">
                      {FAVED_ACTS.map(act => <ActivityCard key={act.api_id} data={act} />)}
                    </div>

                    <div className="l-activity-list__footer">
                      <Link
                        to={PAGES.ACTIVITIES.PATH}
                        className="c-button c-button--primary c-button--wide"
                      >
                        View all activities
                      </Link>
                    </div>
                  </>
                )
                : (
                  <div>
                    <h4 className="c-heading c-heading--h4">YOU DON’T HAVE ANY FAVOURITES YET - TRY ADDING SOME!</h4>
                    <Link to={PAGES.ACTIVITIES.PATH} className="c-button c-button--primary">Browse Activities</Link>
                  </div>
                )
            }
          </div>
        </section>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allActivityPages(
      filter: {
        guide_app_only: { ne: true },
      }
    ) {
      edges {
        node {
          id
          api_id
          name
          location
          absolute_slug
          destination_name
          sliding_first_picture_id
          day_ids
          options {
            api_id
            price
            name
            hidden
            archived
            seasonal_pricings {
              id
              price
              available_from
              available_to
              hidden
            }
          }
        }
      }
    }

    allActivityImages {
      edges {
        node {
          api_id
          imgix_url
        }
      }
    }
  }
`;

UserFavouritesPage.propTypes = {
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(UserFavouritesPage);
